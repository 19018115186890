import request from '../utils/request'

/**
 * 获取实时记录信息接口
 * 
 * @param {Object} data 参数
 */
export function getRealTimeRecord(data) {
	return request({
		url: '/api/home/data/findCount',
		method: 'post',
		data
	})
}

/**
 * 获取院系得分情况 -- 日
 * 
 * @param {Object} data 参数
 */
export function getFacultyScoreByDay(data) {
	return request({
		url: '/api/home/data/getDepDayLineChart',
		method: 'post',
		data
	})
}

/**
 * 获取院系得分情况 -- 周
 * 
 * @param {Object} data 参数
 */
export function getFacultyScoreByWeek(data) {
	return request({
		url: '/api/home/data/getDepWeekLineChart',
		method: 'post',
		data
	})
}

/**
 * 获取院系得分情况 -- 月
 * 
 * @param {Object} data 参数
 */
export function getFacultyScoreByMonth(data) {
	return request({
		url: '/api/home/data/getDepMonthLineChart',
		method: 'post',
		data
	})
}

/**
 * 获取班级饼图数据信息
 * 
 * @param {Object} data 参数
 */
export function getClassPieChart(data) {
	return request({
		url: '/api/home/data/getClassPieChart',
		method: 'post',
		data
	})
}

/**
 * 获取学生饼图数据信息
 * 
 * @param {Object} data 参数
 */
export function getStudentPieChart(data) {
	return request({
		url: '/api/home/data/getStudentPieChart',
		method: 'post',
		data
	})
}

/**
 * 获取光荣榜数据信息接口
 * 
 * @param {Object} data 参数
 */
export function getGloryData(data) {
	return request({
		url: '/api/home/data/findHonorResult',
		method: 'post',
		data
	})
}