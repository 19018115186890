<template>
	<div id="app">
		<!-- 标题 -->
		<div class="title">
			<div class="time"> {{time}} </div>
			<img src="../../assets/bigscreen/all.png" class="all" alt="">
		</div>

		<!-- 主内容部分 -->
		<div class="content">
			<!-- 左边模块div -->
			<div class="left">
				<!--数据统计-->
				<div class="leftStatistics">
					<div class="titleBlock">今日录入记录<br />{{count.todayTotalCount}}条</div>
					<div class="flexRow" style="margin-right:80px">
						<div class="sumItem">
							<div class="sumGreen">
								班级总加分：{{count.todayClassTotalAddScore}}分
							</div>
							<div class="sumRed">
								班级总扣分：{{count.todayClassTotalReduceScore}}分
							</div>
						</div>
						<div class="sumItem">
							<div class="sumGreen">
								学生总加分：{{count.todayStudentTotalAddScore}}分
							</div>
							<div class="sumRed">
								学生总扣分：{{count.todayStudentTotalReduceScore}}分
							</div>
						</div>
					</div>

					<div class="titleBlock">实时<br />预警</div>
					<div class="sumItem">
						<div class="sumGreen">
							当月高分预警：{{count.currentMonthHighWarnCount}}人
						</div>
						<div class="sumRed">
							当月低分预警：{{count.currentMonthLowerWarnCount}}人
						</div>
					</div>
				</div>

				<!--院系得分情况折线图-->
				<div class="leftCollegeScore">
					<div class="subTitle" style="text-align: left;padding-left: 3.125rem;">
						院系得分情况
					</div>
					<div class="collegeScoreBottom">
						<div class="collegeScoreTab">
							<div :class="[currentTab == 0?'collegeScoreTabItem collegeScoreTabChoose':'collegeScoreTabItem']"
								@click='tab(0)'>
								按日
							</div>
							<div :class="[currentTab == 1?'collegeScoreTabItem collegeScoreTabChoose':'collegeScoreTabItem']"
								@click='tab(1)'>
								按周
							</div>
							<div :class="[currentTab == 2?'collegeScoreTabItem collegeScoreTabChoose':'collegeScoreTabItem']"
								@click='tab(2)'>
								按月
							</div>
						</div>
						<div ref="chart1" style="width:53.125rem;height: 15.9375rem;"></div>
					</div>
				</div>

				<!--班级、学生考核分类得分饼图-->
				<div class="leftStudentAssess flexRow">
					<div class="leftStudentAssessItem">
						<div class="subTitle">
							班级考核分类得分
						</div>
						<div ref="chart2" style="width: 30.625rem;height: 25rem;"></div>
					</div>
					<div class="leftStudentAssessItem">
						<div class="subTitle">
							学生考核分类得分
						</div>
						<div ref="chart3" style="width: 30.625rem;height: 25rem;"></div>
					</div>
				</div>
			</div>

			<!-- 右边模块div -->
			<div class="right">
				<!--光荣榜-->
				<div class="rightTop">
					<div class="rightFacultyGlory">
						<div class="subTitle">
							院系光荣榜
						</div>
						<div ref="chart4" style="height: 8.75rem;margin-left:1.25rem"></div>

						<div class="subTitle">
							班级光荣榜
						</div>
						<div ref="chart5" style="height: 8.75rem;margin-left:1.25rem"></div>
					</div>

					<div class="rightStudentGlory">
						<div class="subTitle">
							学生光荣榜
						</div>
						<div class="rightStudentGloryList">
							<div class="rightStudentGloryItem" v-for="item in studentList">
								<img :src="'https://www.zjxsgl.com'+item.photo" class="rightStudentGloryIcon" alt="">
								<div class="rightStudentGloryTextA">
									<div class="rightStudentGloryText" style="color:rgba(246,152,70,0.8)">
										{{item.name}}：{{item.score}}分
									</div>
									<div class="rightStudentGloryText">{{item.depName}} {{item.className}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as api from "@/api/bigscreen";
	import * as echarts from "echarts";
	export default {
		components: {

		},
		data() {
			return {
				time: '',

				count: {
					todayTotalCount: '0', // 今日录入记录数

					todayClassTotalAddScore: '0', // 班级总加分数
					todayClassTotalReduceScore: '0', // 班级总扣分数

					todayStudentTotalAddScore: '0', // 学生总加分数
					todayStudentTotalReduceScore: '0', // 学生总扣分数

					currentMonthHighWarnCount: '0', // 当月高分预警人数
					currentMonthLowerWarnCount: '0', // 当月低分预警人数

				},

				currentTab: 0,
				studentList: [],
			}
		},

		created() {
			let me = this;
			me.loadData();
		},

		mounted() {
			let me = this;
			// 定时每隔100毫秒获取一次系统当前时间带星期
			setInterval(() => {
					me.getTime()
				}, 1000),

				// 定时刷新页面数据
				setInterval(() => {
					me.loadData();
				}, 1000 * 60)

		},

		methods: {

			loadData() {
				let me = this;
				// 获取实时记录信息接口
				me.getRealTimeRecord();
				// 获取院系得分情况
				me.getLine(0);
				// 获取班级、学生考核信息
				me.getClassAndStudentPieChart();
				// 获取光荣榜数据信息
				me.getGloryData();
			},

			/**
			 * 获取系统当前日期带星期
			 */
			getTime() {
				let yy = new Date().getFullYear()
				let mm = new Date().getMonth() + 1
				let dd = new Date().getDate()
				let hh = new Date().getHours()
				let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
				let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
				let str = "星期" + "日一二三四五六".charAt(new Date().getDay());
				this.time = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss + ' ' + str
			},

			/**
			 * 获取实时记录信息接口
			 */
			async getRealTimeRecord() {
				let me = this;
				await api.getRealTimeRecord().then((res) => {
					if (1 == res.status) {
						me.count = res.data;
					}
				});
			},

			/**
			 * 院系得分情况按钮点击事件
			 * 
			 * @param {Object} e 点击的按钮
			 */
			tab(e) {
				let me = this;
				me.currentTab = e
				me.getLine(e)
			},

			/**
			 * 获取院系得分情况
			 * 
			 * @param {Object} type 0:按日 1:按周 2:按月
			 */
			async getLine(type) {
				let me = this;
				// 请求参数信息
				let params = {};
				if (type == 0) {
					await api.getFacultyScoreByDay(params).then((res) => {
						if (1 == res.status) {
							const list = res.data;
							me.handleFacultyScoreData(list);
						}
					});
				} else if (type == 1) {
					await api.getFacultyScoreByWeek(params).then((res) => {
						if (1 == res.status) {
							const list = res.data;
							me.handleFacultyScoreData(list);
						}
					});
				} else if (type == 2) {
					await api.getFacultyScoreByMonth(params).then((res) => {
						if (1 == res.status) {
							const list = res.data;
							me.handleFacultyScoreData(list);
						}
					});
				}
			},

			/**
			 * 处理院系得分信息
			 * 
			 * @param {Object} list 院系得分信息
			 */
			handleFacultyScoreData(list) {
				let me = this;
				let series = [];
				let date = [];
				for (let i in list) {
					series.push({
						name: list[i].dname,
						type: 'line',
						symbol: 'none', //去掉原点
						smooth: true, //平滑处理
						data: list[i].dataList,
					})
				}

				for (let i in list[0].dateList) {
					date.push(list[0].dateList[i]);
				}
				me.chartOneInit(series, date)
			},

			/**
			 * 院系得分情况折线图
			 * 
			 * @param {Object} series 坐标系数
			 * @param {Object} dateList 数据信息
			 */
			chartOneInit(series, dateList) {
				let me = this;
				me.chart1 = echarts.init(me.$refs.chart1)
				me.chart1.setOption({
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						icon: 'rect',
						textStyle: {
							color: '#fff'
						}
					},
					grid: {
						left: '3%',
						right: '3%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: dateList,
						axisLabel: {
							textStyle: {
								color: '#198fff'
							},
						}
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							textStyle: {
								color: '#198fff'
							}
						}
					},
					series: series
				})
			},

			/**
			 * 获取班级及学生考核分类得分信息
			 */
			async getClassAndStudentPieChart() {
				let me = this;
				const colorList = [
					'rgba(255,67,67',
					'rgba(246,152,70',
					'rgba(246,213,74',
					'rgba(69,219,247',
					'rgba(68,175,240',
					'rgba(71,119,245',
					'rgba(80,69,246',
					'rgba(173,70,243',
					'rgba(248,69,241',
					'rgba(205,33,158'
				]

				// 获取班级考核分类得分信息
				await api.getClassPieChart().then((res) => {
					if (1 == res.status) {
						const list = res.data;
						me.handleClassPieChartData(list, colorList);

					}
				});

				// 获取学生考核分类得分信息
				await api.getStudentPieChart().then((res) => {
					if (1 == res.status) {
						const list = res.data;
						me.handleStudentPieChartData(list, colorList);
					}
				});

			},

			/**
			 * 处理班级考核分类得分信息
			 * 
			 * @param {Object} list 数据集合
			 * @param {Object} colorList 颜色集合
			 */
			handleClassPieChartData(list, colorList) {
				let me = this;
				let listData = [];
				let childrenData = [];
				let sum = 0;
				for (let i in list) {
					listData.push({
						value: 0,
						num: list[i].score,
						name: list[i].category,
						itemStyle: {
							// 内部颜色透明度
							color: colorList[i] + ',1)'
						}
					})
					for (let j in list[i].children) {
						childrenData.push({
							value: Math.abs(list[i].children[j].score),
							num: list[i].children[j].score,
							name: list[i].children[j].depName,
							itemStyle: {
								color: colorList[i]
							},

						})
						listData[i].value = listData[i].value + Math.abs(list[i].children[j].score)
					}
					sum = listData[i].value + sum
				}

				for (let i in childrenData) {
					const percent = Math.abs(childrenData[i].value) / sum;
					if (percent > 0.03) { // 图例比例低于 3% 不显示
						childrenData[i].label = {
							color: '#51aafe', // 班级饼图外部图例颜色
							textBorderColor: '#51aafe',
							show: true,
							formatter: (e) => {
								return e.data.name + '\n' + e.data.num + "分";
							}
						}
						childrenData[i].labelLine = {
							length: 5,
							show: true
						}
					}
					childrenData[i].itemStyle = {
						color: childrenData[i].itemStyle.color + ',' + percent.toFixed(4) * 60 + ')'
					}
				}

				me.chart2 = echarts.init(me.$refs.chart2)
				me.chart2.setOption({
					tooltip: {},
					series: [{
						name: '班级考核分类得分',
						type: 'pie',
						radius: [0, '55%'],
						itemStyle: { // 饼图部分间距
							borderColor: '#153d66',
							borderWidth: 0.2,
						},
						label: {
							position: 'inner',
							fontSize: 12,
							color: '#fff', // 班级饼图内部图例颜色
							textBorderColor: '#51aafe',
							formatter(e) {
								return e.data.name + '\n' + e.data.num
							}
						},
						labelLine: {
							show: false
						},
						data: listData
					}, {
						name: '班级考核分类得分',
						type: 'pie',
						radius: ['60%', '70%'],
						itemStyle: { // 饼图部分间距
							borderColor: '#153d66',
							borderWidth: 0.2
						},
						labelLine: {
							show: false
						},
						label: {
							show: false
						},
						data: childrenData
					}]
				})
			},

			/**
			 * 处理班级考核分类得分信息
			 * 
			 * @param {Object} colorList 颜色集合
			 * @param {Object} list 数据集合
			 */
			handleStudentPieChartData(list, colorList) {
				let me = this;
				let listData = [];
				let childrenData = [];
				let sum = 0;
				for (let i in list) {
					listData.push({
						value: 0,
						num: list[i].score,
						name: list[i].category,
						itemStyle: {
							color: colorList[i] + ',1)' // 内部颜色透明度
						}
					})
					for (let j in list[i].children) {
						childrenData.push({
							value: Math.abs(list[i].children[j].score),
							num: list[i].children[j].score,
							name: list[i].children[j].depName,
							itemStyle: {
								color: colorList[i]
							},
						})
						listData[i].value = listData[i].value + Math.abs(list[i].children[j].score)
					}
					sum = listData[i].value + sum
				}

				for (let i in childrenData) {
					const percent = Math.abs(childrenData[i].value) / sum;
					if (percent > 0.03) { // 图例比例低于 3% 不显示
						childrenData[i].label = {
							fontSize: 12,
							position: 'top',
							color: '#51aafe', // 学生饼图外部图例颜色
							textBorderColor: '#7bace7',
							show: true,
							formatter: (e) => {
								return e.data.name + '\n' + e.data.num + "分";
							}
						}
						childrenData[i].labelLine = {
							length: 5,
							show: true
						}
					}
					childrenData[i].itemStyle = {
						color: childrenData[i].itemStyle.color + ',' + percent.toFixed(4) * 60 + ')' // 外部颜色透明度
					}
				}

				me.chart3 = echarts.init(me.$refs.chart3)
				me.chart3.setOption({
					tooltip: {},
					series: [{
						name: '学生考核分类得分',
						type: 'pie',
						radius: [0, '55%'],
						itemStyle: { // 饼图部分间距
							borderColor: '#153d66',
							borderWidth: 0.2
						},
						label: {
							position: 'inner',
							fontSize: 12,
							color: '#fff', // 学生饼图内部图例颜色
							textBorderColor: '#7bace7',
							formatter(e) {
								return e.data.name + '\n' + e.data.num
							}
						},
						labelLine: {
							show: false
						},
						data: listData
					}, {
						name: '学生考核分类得分',
						type: 'pie',
						radius: ['60%', '70%'],
						itemStyle: { // 饼图部分间距
							borderColor: '#153d66',
							borderWidth: 0.2
						},
						labelLine: {
							show: false
						},
						label: {
							show: false
						},
						data: childrenData
					}]
				});
			},

			/**
			 * 获取光荣榜数据信息
			 */
			async getGloryData() {
				let me = this;
				await api.getGloryData().then((res) => {
					if (1 == res.status) {
						const list = res.data;
						me.studentList = list.studentList;
						let depX = [];
						let depData = [];
						for (let i in list.depList) {
							depX.push(list.depList[i].name);
							depData.push(list.depList[i].score);
						}

						let classX = [];
						let classData = [];
						for (let i in list.classList) {
							if (i <= 5) {
								classX.push(list.classList[i].name);
								classData.push(list.classList[i].score);
							}
						}

						let reduceX = [];
						let reduceData = [];
						for (let i in list.reduceBehaviorList) {
							reduceX.push(list.reduceBehaviorList[i].name);
							reduceData.push(Math.abs(list.reduceBehaviorList[i].score));
						}

						let addX = [];
						let addData = [];
						for (let i in list.addBehaviorList) {
							addX.push(list.addBehaviorList[i].name);
							addData.push(list.addBehaviorList[i].score);
						}
						me.chartFourInit(depX, depData);
						me.chartFiveInit(classX, classData);
						me.chartSixInit(reduceX, reduceData);
						me.chartSevenInit(addX, addData);
					}
				});
			},

			/**
			 * 院系光荣榜
			 * 
			 * @param {Object} x x轴数据
			 * @param {Object} data 数据
			 */
			chartFourInit(x, data) {
				let me = this;
				me.chart4 = echarts.init(me.$refs.chart4);
				me.chart4.setOption({
					color: '#fd866a', // 柱图颜色
					grid: {
						top: 0,
						bottom: 20,
						left: 80,
						right: 70,
					},
					xAxis: {
						type: 'value',
						axisLabel: {
							textStyle: {
								color: '#198fff'
							}
						},
						splitLine: { // 竖线
							show: true
						},
						type: 'value',
						show: true,
					},
					yAxis: {
						splitLine: {
							show: false
						},
						axisLine: { // y轴
							show: true
						},
						type: 'category',
						axisTick: {
							show: true
						},
						inverse: true,
						data: x,
						axisLabel: {
							color: '#A7D6F4', // 名称颜色
							fontSize: 12, // 名称字号
						}
					},
					series: [{
						data: data,
						type: 'bar',
						barWidth: 12,
						showBackground: true,
						backgroundStyle: {
							color: 'rgba(180, 180, 180, 0.3)'
						},
						label: {
							show: true,
							position: 'right',
							color: '#A7D6F4', // 分值颜色
							fontSize: 14,
							distance: 15,
							formatter: '{c} 分'
						},
					}]
				})
			},

			/**班级光荣榜
			
			 * @param {Object} x x轴数据
			 * @param {Object} data 数据
			 */
			chartFiveInit(x, data) {
				let me = this;
				me.chart5 = echarts.init(me.$refs.chart5)
				me.chart5.setOption({
					color: '#fdb36a', // 柱图颜色
					grid: {
						top: 0,
						bottom: 30,
						left: 80,
						right: 70,
					},
					xAxis: {
						type: 'value',
						axisLabel: {
							textStyle: {
								color: '#198fff'
							}
						},
						splitLine: {
							show: true // 竖线
						},
						type: 'value',
						show: true,
					},
					yAxis: {
						splitLine: {
							show: false
						},
						axisLine: { // y轴
							show: true
						},
						type: 'category',
						axisTick: {
							show: true
						},
						inverse: true,
						data: x,
						axisLabel: {
							color: '#A7D6F4', // 名称颜色
							fontSize: 12, // 名称字号
						}
					},
					series: [{
						data: data,
						type: 'bar',
						barWidth: 12,
						showBackground: true,
						backgroundStyle: {
							color: 'rgba(180, 180, 180, 0.3)'
						},
						label: {
							show: true,
							position: 'right',
							color: '#A7D6F4', // 分值颜色
							fontSize: 14,
							distance: 15,
							formatter: '{c} 分'
						},
					}]
				})
			},

			/**
			 * 评分明细扣分排行
			 * 
			 * @param {Object} x x轴数据
			 * @param {Object} data 数据
			 */
			chartSixInit(x, data) {
				let me = this;
				me.chart6 = echarts.init(me.$refs.chart6);
				me.chart6.setOption({
					tooltip: {},
					legend: {
						show: false
					},
					grid: {
						top: 20,
						bottom: 20,
						left: 100,
						right: 60,
					},
					xAxis: [{
						splitLine: {
							show: false
						},
						type: 'value',
						show: false,
					}],
					yAxis: [{
						splitLine: {
							show: false
						},
						axisLine: {
							show: false
						},
						type: 'category',
						axisTick: {
							show: false
						},
						inverse: false,
						data: x,
						axisLabel: {
							color: '#A7D6F4',
							fontSize: 14,
						}
					}],
					series: [{
						type: 'bar',
						barWidth: 12,
						label: {
							show: true,
							position: 'right',
							color: '#A7D6F4',
							fontSize: 14,
							distance: 15,
							formatter: '-{c} 分'
						},
						itemStyle: {
							barBorderRadius: [0, 20, 20, 0],
							color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
								offset: 0,
								color: '#51C5FD'
							}, {
								offset: 1,
								color: '#005BB1'
							}], false),
						},
						data: data
					}],
				})
			},

			/**
			 * 评分明细加分排行
			 * 
			 * @param {Object} x
			 * @param {Object} data
			 */
			chartSevenInit(x, data) {
				let me = this;
				me.chart7 = echarts.init(me.$refs.chart7);
				me.chart7.setOption({
					tooltip: {},
					legend: {
						show: false
					},
					grid: {
						top: 20,
						bottom: 20,
						left: 100,
						right: 60,
					},
					xAxis: [{
						splitLine: {
							show: false
						},
						type: 'value',
						show: false,
					}],
					yAxis: [{
						splitLine: {
							show: false
						},
						axisLine: {
							show: false
						},
						type: 'category',
						axisTick: {
							show: false
						},
						inverse: true,
						data: x,
						axisLabel: {
							color: '#A7D6F4', // 加分名称
							fontSize: 14,
						}
					}],
					series: [{
						type: 'bar',
						barWidth: 12,
						label: {
							show: true,
							position: 'right',
							color: '#A7D6F4', // 加分分值
							fontSize: 14,
							distance: 15,
							formatter: '{c} 分'
						},
						itemStyle: {
							barBorderRadius: [0, 20, 20, 0],
							color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
								offset: 0,
								color: '#d0a00e' // 加分明细柱状图颜色
							}, {
								offset: 1,
								color: '#eb2100' // 加分明细柱状图颜色
							}], false),
						},
						data: data
					}],
				})
			},

		},
	}
</script>

<style>
	#app {
		width: 120rem;
		height: 67.5rem;
		background-color: #061835;
	}

	/* 平台名称样式 */
	.title {
		background: url('../../assets/bigscreen/1.png');
		background-size: 100% 100%;
		-webkit-background-size: 100% 100%;
		-o-background-size: 100% 100%;
		width: 120rem;
		height: 8.125rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	/* 系统时间样式 */
	.time {
		margin-top: 6vh;
		margin-left: 2vw;
		color: #7bace7;
	}

	/* 全屏按钮样式 */
	.all {
		width: 1.875rem;
		height: 1.875rem;
		margin-top: 2vh;
		margin-right: 1vw;
	}

	/* 平台内容部分样式 */
	.content {
		width: 115rem;
		height: 56.25rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 1.875rem 2.5rem 0rem 2.5rem;
	}

	/* 平台左侧布局样式 */
	.left {
		width: 61.25rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	/* 今日数据统计布局样式 */
	.leftStatistics {
		background: url('../../assets/bigscreen/2.png');
		background-size: 100% 100%;
		-webkit-background-size: 100% 100%;
		-o-background-size: 100% 100%;
		height: 8.4375rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
		padding: 0rem 1.875rem;
	}

	/* 院系等分情况统计布局样式 */
	.leftCollegeScore {
		background: url('../../assets/bigscreen/3.png');
		background-size: 100% 100%;
		-webkit-background-size: 100% 100%;
		-o-background-size: 100% 100%;
		height: 20.3125rem;
	}

	/* 院系得分折线样式 */
	.collegeScoreBottom {
		width: 60rem;
		height: 15.9375rem;
		display: flex;
	}

	/* 折线图日周月按钮样式 */
	.collegeScoreTab {
		width: 5rem;
		display: flex;
		flex-direction: column;
		margin-left: 1.25rem;
		margin-top: 3.125rem;
		color: #7bace7;
	}

	.collegeScoreTabItem {
		background-color: #153d66;
		width: 3.125rem;
		height: 1.875rem;
		margin-bottom: 0.625rem;
		text-align: center;
		line-height: 1.875rem;
		color: #27cffa;
		border: 0.0625rem solid #27cffa;
		/*按钮边框*/
		box-shadow: 0rem 0rem 0.1875rem #27cffa;
		border-radius: 0.3125rem;
	}

	/* 按钮颜色 */
	.collegeScoreTabChoose {
		background-color: #4e7db6;
		color: #7bace7;
	}

	/* 班级学生分类得分饼图样式 */
	.leftStudentAssess {
		background: url('../../assets/bigscreen/4.png');
		background-size: 100% 100%;
		-webkit-background-size: 100% 100%;
		-o-background-size: 100% 100%;
		height: 26.875rem;
		/*饼图高度*/
		display: flex;
		flex-direction: row;
	}

	.leftStudentAssessItem {
		height: 26.875rem;
		width: 36.875rem;
	}

	.leftStudentAssess flexRow {
		width: 61.25rem;
		height: 28.125rem;
	}

	/* 平台右侧布局样式 */
	.right {
		width: 53.125rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	/* 光荣榜布局样式 */
	.rightTop {
		height: 23.75rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	/* 院系班级光荣榜样式 */
	.rightFacultyGlory {
		background: url('../../assets/bigscreen/5.png');
		background-size: 100% 100%;
		-webkit-background-size: 100% 100%;
		-o-background-size: 100% 100%;
		width: 34.0625rem;
	}

	/*学生光荣榜*/
	.rightStudentGlory {
		background: url('../../assets/bigscreen/7.png');
		background-size: 100% 100%;
		-webkit-background-size: 100% 100%;
		-o-background-size: 100% 100%;
		width: 18.75rem;
	}

	.rightStudentGloryList {
		width: 15.125rem;
		height: 82%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 1.75rem;
		padding-right: 0.625rem;
		overflow-y: scroll;
	}

	.rightStudentGloryList::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 0.3125rem;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 0.0625rem;
	}

	.rightStudentGloryList::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 0.625rem;
		box-shadow: inset 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.2);
		background: #1993ff;
	}

	.rightStudentGloryList::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		box-shadow: inset 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.2);
		border-radius: 0.625rem;
		background-color: #014177;
	}

	.rightStudentGloryItem {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin: 0.625rem 0rem;
	}

	.rightStudentGloryIcon {
		width: 3.125rem;
		height: 3.125rem;
		border-radius: 3.125rem;
	}

	.rightStudentGloryTextA {
		width: 11rem;
	}

	.rightStudentGloryText {
		margin-left: 0.625rem;
		color: #A7D6F4;
		font-size: 0.8125rem;
	}




	/* 板块标题方形布局样式 */
	.titleBlock {
		padding: 0.625rem;
		border: 0.0625rem solid #27cffa;
		box-shadow: 0 0 0.625rem #27cffa;
		border-radius: 0.3125rem;
		font-size: 120%;
		font-weight: bold;
		text-align: center;
		letter-spacing: 0.25rem;
		color: #7bace7;
	}

	.flexRow {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		color: #7bace7;
	}

	/* 数据统计加分框布局样式 */
	.sumGreen {
		padding: 0rem 0.625rem;
		line-height: 2.1875rem;
		text-align: center;
		color: #62a178;
		border: 0.0625rem solid #4b6dad;
		border-radius: 0.125rem;
		letter-spacing: 0rem;
		white-space: nowrap
	}

	/* 数据统计扣分框布局样式 */
	.sumRed {
		padding: 0rem 0.625rem;
		line-height: 2.1875rem;
		text-align: center;
		color: #bd5661;
		border: 0.0625rem solid #4b6dad;
		border-radius: 0.125rem;
		letter-spacing: 0rem;
		white-space: nowrap
	}

	/* 板块文字标题样式 */
	.subTitle {
		height: 3.125rem;
		/*图形位置*/
		text-align: center;
		line-height: 3.75rem;
		/*标题位置*/
		font-weight: bold;
		font-size: 120%;
		color: #7bace7;
	}
</style>
